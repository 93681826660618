footer {
    background: var(--color-bg-varient);
    padding: 3rem 5rem;
    /* padding-bottom: 5rem; */
    text-align: center;
    font-size: 1rem;
    margin-top: 10rem;
}

footer a {
    color: var(--color-primary);
} 

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}

/* -------------------------Media Queries (Small Devices)-------------------------- */

@media screen and (max-width: 600px) {
    
}