
.experience__container {
    display: grid;
    gap: 15%;
    height: 1500px;
    grid-template-columns: 10% 35% 40%;
    grid-template-rows: 1fr 1fr;
}

.education__container {
    display: grid;
    gap: 15%;
    height: 850px;
    grid-template-columns: 40% 10% 40%;
    grid-template-rows: 1fr 1fr;
}

.education__container > .educationDottedLine {
    grid-column: 2;
    border-left: 1px dotted white;
    height: 830px;
    left: 55%;
    margin-bottom: 10%;
}

.experienceDottedLine {
    grid-column: 1;
    border-left: 1px dotted white;
    height: 1350px;
    left: 10%;
    margin-top: 3rem;
}

.experience__content_1 {
    grid-row: 1;
    grid-column: 2;
    left: 15%;
    margin-top: 1rem;
    margin-left: 3rem;
    width: 460px;
    font-size: 1.2rem;
}
.experience__content_2 {
    /* grid-row: 2; */
    grid-column: 2;
    left: 15%;
    margin-top: 7rem;
    margin-left: 3rem;
    width: 460px;
    font-size: 1.2rem;
}

.experience__content_1 > .role, .experience__content_2 > .role {
    font-style: italic;
}

.education__container > .educationDottedLine >.education__content_1 {
    grid-row: 1;
    grid-column: 3;
    left: 15%;
    margin-top: 1rem;
    margin-left: 3rem;
    width: 460px;
    font-size: 1.2rem;
}

.education__container > .educationDottedLine > .education__content_2 {
    grid-row: 2;
    grid-column: 3;
    left: 15%;
    margin-top: 7rem;
    margin-left: 3rem;
    width: 460px;
    font-size: 1.2rem;
}

.education__content_1 > .degree {
    font-style: italic;
}

.education__content_2 > .degree {
    font-style: italic;
}

.experience__icon {
    background: 1rem solid var(--color-bg-varient);
    font-size: 2rem;
    left: 20px;
    margin-left: 10%;
    margin-top: -10%;
}

.education__icon {
    margin-left: 15%;
    margin-top: -10%;
    left: 10px;
    font-size: 2rem;
}

.degree .role {
    font-style: italic;
}

.experience__container > .experience__logo_1 {
    grid-row: 1;
    grid-column: 3;
    margin-left: -15rem;
    margin-top: 17rem;
    transform: rotateY(10deg);
    width: 130%;
}

.experience__container > .experience__logo_2 {
    grid-row: 2;
    grid-column: 3;
    transform: rotateY(10deg);
    width: 125%;
    margin-top: -200%;
    margin-left: -40%;
}

.education__container > .neu__logo {
    grid-row: 1;
    grid-column: 1;
    transform: rotateY(10deg);
    width: 80%;
    margin-top: 4rem;
}

.education__container > .sppu__logo {
    grid-row: 2;
    grid-column: 1;
    transform: rotateY(10deg);
    width: 80%;
    margin-top: -105%;
}

/* -------------------------Media Queries (Medium Devices)-------------------------- */

@media screen and (max-width: 1024px) {
    .experience__container {
        height: 2600px;
        grid-template-columns: 10% 600px;
        grid-template-rows: 50%;
        margin-top: 0%;
    }

    .education__container {
        height: 1020px;
        grid-template-columns: 10% 90%;
        grid-template-rows: 30% 60%;
        width: 600px;
    }

    .experience__container > .experienceDottedLine{
        grid-column: 1;
    }
    .education__container > .educationDottedLine {
        grid-column: 1;
        left: 0%;
        margin-top: -1030%;
        height: fit-content;
    }
    .experience__content_1 {
        grid-column: 2;
        grid-row: 2;
        margin-top: 8rem;
        margin-left: 3rem;
        width: 500px;
    }

    .experience__content_2 {
        grid-row: 4;
        grid-column: 2;
        left: 15%;
        margin-top: 32rem;
        margin-left: 3rem;
        width: 500px;
    }
    
    .education__container > .educationDottedLine > .education__content_1 {
        grid-row: 6;
        grid-column: 2;
        margin-top: 35rem;
        width: 500px;
    }

    .education__container > .educationDottedLine > .education__content_2 {
        grid-row: 8;
        grid-column: 2;
        width: 500px;
        margin-top: 30rem;
        margin-bottom: 2rem;
    }
    .experience__container > .experience__logo_1 {
        grid-row: 1;
        grid-column: 2;
        width: 85%;
        margin-left: -4rem;
        margin-top: 4rem;
        transform: rotateY(10deg);
    }
    .experience__container > .experience__logo_2 {
        grid-row: 3;
        grid-column: 2;
        width: 80%;
        transform: rotateY(10deg);
        margin-top: -280%;
        margin-left: -4rem;
    }
    .education__container > .neu__logo {
        grid-column: 2;
        grid-row: 5;
        width: 70%;
        margin-top: -353%;
        margin-left: -4rem;
    }
    .education__container > .sppu__logo {
        grid-row: 7;
        grid-column: 2;
        width: 70%;
        margin-left: -4rem;
        margin-top: -250%;
    } 
}

/* -------------------------Media Queries (Small Devices)-------------------------- */

@media screen and (max-width: 600px) {
    .experience__container {
        grid-template-columns: 10% 600px;
        grid-template-rows: 50%;
        height: 3000px;
    }

    .education__container {
        height: 1250px;
        grid-template-columns: 10% 90%;
        grid-template-rows: 30% 60%;
        width: 600px;
    }

    .experience__container > .experienceDottedLine{
        grid-template-columns: 1;
        height: fit-content;
    }

    .education__container > .educationDottedLine {
        grid-template-columns: 1;
        margin-top: -1750%;
        height: fit-content;
    }

    .experience__container > .experience__logo_1 {
        grid-column: 2;
        grid-row: 1;
        margin-top: 7rem;
        width: 55%;
        margin-left: -4rem;
        z-index: 1;
    }

    .experience__content_1 {
        grid-column: 2;
        grid-row: 2;
        margin-top: 8rem;
        margin-left: 3rem;
        width: 300px;
    }

    .experience__container > .experience__logo_2 {
        grid-column: 2;
        grid-row: 3;
        width: 60%;
        margin-left: -4rem;
        margin-top: -310%;
    }
    
    .experience__content_2 {
        margin-top: 20rem;
        width: 270px;
        margin-bottom: 3rem;
    }

    .education__container > .neu__logo {
        grid-column: 2;
        grid-row: 5;
        margin-left: -2rem;
        width: 80%;
        margin-top: -740%;
        z-index: 1;
    }

    .education__container > .educationDottedLine > .education__content_1 {
        grid-column: 2;
        grid-row: 6;
        margin-top: 22rem;
        width: 270px;
    }
    
    .education__container > .sppu__logo {
        /* margin-bottom: 20rem; */
        margin-top: -525%;
        margin-left: -2rem;
        width: 80%;
    }

    .education__container > .educationDottedLine > .education__content_2 {
        margin-top: 25rem;
        width: 270px;
    }

}
