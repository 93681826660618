#skills {
    background-image: url('../../assets/background-skills-1.jpg');
    background-size: cover;
    padding-top: 6rem;
    padding-bottom: 5rem;
    /* border-top-left-radius: 270% 160px 50px; */
    border-top-right-radius: 0 ;
}

.skills__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.skills__container > div {
    /* background: var(--color-bg-varient); */
    background: rgb(0,0,0);
    opacity: 0.6;
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
}

.skills__container > div:hover {
    background: rgb(0,0,0);
    opacity: 1;
    border-color: var(--color-primary-varient);
    cursor: default;
    transition: var(--transition);
}

.skills__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.skills__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.skills__details {
    display: flex;
    gap: 1rem;
}

.skills__details-icons {
    margin-top: 1.5px;
    color: var(--color-primary);
    font-size: 1.5rem;
}

/* -------------------------Media Queries (Medium Devices)-------------------------- */

@media screen and (max-width: 1024px) {
    .skills__container{
        grid-template-columns: 1fr;
    }
    
    .skills__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    .skills__content {
        padding: 1rem;
    }

}

/* -------------------------Media Queries (Small Devices)-------------------------- */

@media screen and (max-width: 600px) {

    .skills__container {
        gap: 1rem;
    }

    .skills__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}
