@import url('https://fonts.googleapis.com/css2?family=Water+Brush&display=swap');

header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    background-image: url('../../assets//background.jpg');
    background-size: cover;
}


.name {
    font-family: 'Water Brush', cursive;
    font-size: 5rem;
}

.typing-content {
    font-size: 1.5rem;
    font-style: normal;
}

.header_container {
    margin-top: 50%;
    text-align: center;
    top: 25%;
    height: 100%;
    position: relative;
    /* display: flex; */
}

/* .header_container >h5 {
    width: auto; */
    /* color: rgb(16, 229, 200); */
    /* padding: 0.5%;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    border-right: .01em solid blue;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .04em;
    animation: 
    typing 4.5s steps(40),
    blink-caret 1s step-end infinite;
} */

/* The typing effect */
@keyframes typing {
    from { max-width: 0 }
    to { max-width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: blanchedalmond; }
  }

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}    

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 31rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 36rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* -------------------------Media Queries (Medium Devices)-------------------------- */

@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* -------------------------Media Queries (Small Devices)-------------------------- */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .typing-content {
        font-size: 1rem;
        font-style: normal;
    }

    .header_container >h1 {
        font-size: 4rem;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}