/* #testimonials {
    background-image: url('../../assets/background-testimonials-1.jpg');
    background-size: cover;
    padding-top: 6rem;
    padding-bottom: 5rem; */
    /* border-top-left-radius: 270% 160px 50px; */
    /* border-top-right-radius: 0 ;
} */

.container.testimonials__container {
    width: 50%;
    padding-bottom: 4rem;
    /* padding-right: 2.4rem;
    padding-left: 2.4rem; */
}

.client__avatar {
    width: 5.5rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-varient);
}

.testimonial {
    background: var(--color-bg-varient);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.Recommender__review {
    /* color: var(--color-light); */
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}

.swiper-pagination-bullet .swiper-pagination-clickable {
    background: var(--color-primary);
}

.Recommender__role {
    color: var(--color-light);
}

.Recommender__name {
    color: var(--color-primary);
}

/* -------------------------Media Queries (Medium Devices)-------------------------- */

@media screen and (max-width: 1024px) {
    .container.testimonials__container {
        width: 60%;
    }
}

/* -------------------------Media Queries (Small Devices)-------------------------- */

@media screen and (max-width: 600px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
    }

    .Recommender__review {
        width: var(--container-width-sm);
    }
}