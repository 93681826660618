#projects {
    background-image: url('../../assets/background-testimonials-1.jpg');
    background-size: cover;
    padding-top: 6rem;
    padding-bottom: 5rem;
}

.projects__container {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2.5rem;
}

.projects__item {
    background: black;
    opacity: 0.6;
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    text-align: center;
}

.projects__item:hover {
    border-color: var(--color-primary-varient);
    opacity: 1;
}

.project__item-image {
    border-radius: 1.5rem;
    height: 65%;
    overflow: hidden;
}

.projects__item h3 {
    margin: 1.2 0 2rem;
}

/* -------------------------Media Queries (Medium Devices)-------------------------- */

@media screen and (max-width: 1024px) {
    .projects__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* -------------------------Media Queries (Small Devices)-------------------------- */

@media screen and (max-width: 600px) {
    .projects__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}